export const messages = {
  system_admin_news_news: "News",
  system_admin_news_news_management: "News Management",
  system_admin_news_info:
    'On this page, you can manage the content for the "News" section for customers. This section is located on the customer side of the homepage.',
  system_admin_news_publish_date: "Publish Date",
  system_admin_news_title: "Title",
  system_admin_news_creation_date: "Creation Date",
  system_admin_news_creation_status: "Status",
  system_admin_news_publish: "Publish",
  system_admin_news_draft: "Draft",
  system_admin_news_do_you_really_want_to_delete_the_news_item: "Do you really want to delete the news item",
  system_admin_news_item_successfully_created: "News item successfully created",
  system_admin_news_item_successfully_edited: "News item successfully edited",
  system_admin_news_item_successfully_deleted: "News item successfully deleted",
  system_admin_news_create_news: "Create News Item",
  system_admin_news_edit_news: "Edit News Item",
  system_admin_news_editor: "Editor",
  system_admin_news_preview: "Preview",
  system_admin_news_title_in_english: "Title in English",
  system_admin_news_title_in_german: "Title in German",
  system_admin_news_select_tags: "Select Tags",
  system_admin_news_type_the_description_in_english_here: "Type the description in English here",
  system_admin_news_type_the_description_in_german_here: "Type the description in German here",
  system_admin_news_display_news_to_customers: "Display News to Customers",
  system_admin_news_as_long_as_this_checkbox_is_not_checked:
    "As long as this checkbox is not checked, the news item is in draft mode and will not be displayed to the customers.",
  system_admin_news_news_can_only_be_published_if:
    "News can only be published if all the required fields are filled out.",
  system_admin_news_display_to_the_customers: "Publish news item",
  system_admin_news_new_update: "New Update",
};
