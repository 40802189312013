/* eslint-disable */
export const messages = {
  // Header and footer
  common_global_climate_platform: "GLOBAL SUITE",
  common_imprint: "Impressum",
  common_terms_of_services: "AGBs",
  common_data_security: "Datensicherheit",

  // Buttons
  common_button_close: "Schließen",
  common_button_yes: "Ja",
  common_button_continue: "Weiter",
  common_button_no: "Nein",
  common_button_ok: "OK",
  common_button_back: "Zurück",
  common_button_submit: "Übermitteln",
  common_button_cancel: "Abbrechen",
  common_button_download: "Herunterladen",
  common_button_upload: "Hochladen",
  common_button_create: "Erstellen",
  common_button_finalise: "Finalisieren",
  common_button_save: "Speichern",
  common_button_add: "Hinzufügen",
  common_button_start: "Start",
  common_button_start_date: "Startdatum",
  common_button_end_date: "Enddatum",
  common_button_unlock: "Freischalten",
  common_button_send: "Senden",
  common_button_next: "Weiter",
  common_button_import: "Importieren",
  common_button_more: "Mehr anzeigen",
  common_button_less: "Weniger anzeigen",
  common_button_select: "Auswählen",
  common_button_unselect: "Deselektieren",
  common_button_lock: "Sperren",
  common_button_see_details: "Siehe Details",
  common_button_not_now: "Nicht jetzt",
  common_button_details: "Details",
  common_button_finish: "Beenden",
  common_button_clone: "Duplizieren",
  common_button_export_as_excel: "Export als Excel",
  common_button_compare_emission: "Emission vergleichen",
  common_button_generate: "Generieren",
  common_button_deactivate_selected_co2_types: "Ausgewählte CO<sub>2</sub>-Typen deaktivieren",
  common_button_activate_selected_co2_types: "Ausgewählte CO<sub>2</sub>-Typen aktivieren",
  common_button_copy_error_message: "Fehlermeldung kopieren",
  common_button_open_in_new_tab: "In neuem Tab öffnen",
  common_button_changelog: "Änderungsprotokoll",

  // Distance calculator
  common_distance_calculator_origin: "Start",
  common_distance_calculator_destination: "Ziel",
  common_distance_road: "Straße",
  common_distance_rail: "Schiene",
  common_distance_sea: "Seeweg",
  common_distance_air: "Luftlinie",

  // Inputs / tables
  common_general_no_data: "Keine Daten gefunden",
  common_general_type_for_search: "Tippen Sie für die Suche ein",
  common_general_email: "Email",
  common_general_password: "Passwort",
  common_general_company: "Unternehmen",
  common_general_co2_type_bucket: "CO<sub>2</sub> Typ Eimer",
  common_general_editor: "Bearbeiter",
  common_general_date: "Datum",
  common_general_state: "Status",
  common_general_failed_login_attempts: "Fehlerhafte Anmeldungen",
  common_general_number_of_partners: "Eingeladen Partners",
  common_general_quantity: "Menge",
  common_general_status: "Status",
  common_general_category: "Kategorie",
  common_general_currency: "Währung",
  common_general_percentage: "Prozent",
  common_general_account_number: "Kontennummer",
  common_general_internal_order_number: "Interne Auftragsnummer",
  common_general_name: "Name",
  common_general_names: "Namen",
  common_general_code: "Code",
  common_general_model: "Modell",
  common_general_first_name: "Vorname",
  common_general_last_name: "Nachname",
  common_general_creator: "Ersteller",
  common_general_creation_date: "Erstellungsdatum",
  common_general_comment: "Kommentar",
  common_general_address: "Adresse",
  common_general_profile: "Profil",
  common_general_mail_language: "Mail-Sprache",
  common_general_all: "Alle",
  common_general_active: "aktiv",
  common_general_deactivated: "Deaktiviert",
  common_general_loading_data: "Daten werden geladen...",
  common_general_no_data_available: "Keine Daten vorhanden",
  common_general_not_set: "Nicht festgelegt",
  common_general_company_code: "Buchungskreis",
  common_general_company_name: "Unternehmensname",
  common_general_company_codes: "Buchungskreise",
  common_general_order_period: "Buchungsperiode",
  common_general_period: "Zeitraum",
  common_general_scope: "Scope",
  common_general_from: "Von",
  common_general_until: "Bis",
  common_general_versions_count: "Anzahl der Versionen",
  common_general_started: "Gestartet",
  common_general_deadline_at: "Deadline am",
  common_general_evaluation_period: "Evaluierungszeitraum",
  common_general_total: "Gesamt",
  common_general_general: "Allgemein",
  common_general_environment: "Umwelt",
  common_general_social: "Soziales",
  common_general_governance: "Governance",
  common_general_year: "Jahr",
  common_general_validity: "Gültigkeit",
  common_general_valid_from: "Gültig von",
  common_general_valid_until: "Gültig bis",
  common_general_scorecard: "Scorecard",
  common_general_requested_at: "Angefragt am",
  common_general_requested_by: "Angefragt von",
  common_general_select_type: "CO<sub>2</sub> Type wählen",
  common_general_selected_type: "Ausgewählter CO<sub>2</sub> Type",
  common_general_select: "Wählen",
  common_general_type: "Typ",
  common_general_created_at: "Erstellt am",
  common_general_created_by: "Erstellt von",
  common_general_financial_models: "Finanzmodelle",
  common_general_supplier: "Lieferant",
  common_general_suppliers: "Lieferanten",
  common_general_materials: "Materialien",
  common_general_amount: "Betrag",
  common_general_row: "Zeile",
  common_general_template: "Vorlage",
  common_general_icon: "Icon",
  common_general_accounts: "Konten",
  common_general_valid: "Gültig",
  common_general_invalid: "Ungültig",
  common_general_item_selected: "Element ausgewählt",
  common_general_items_selected: "Ausgewählte Elemente",
  common_general_companies_selected: "Ausgewählte Unternehmen",
  common_general_upload_file: "Datei hochladen",
  common_general_upload_file_or_drag_drop_file: "Datei (Klick oder Drag & Drop)",
  common_general_set_starting_version_date: "Startdatum der Version festlegen",
  common_file_upload_drop_file_here: "Datei hier einfügen",
  common_general_lock_version: "Version sperren",
  common_general_lock_version_recommendation:
    "Das Sperren einer Version wird nach der Erstellung eines Berichts empfohlen, um sicherzustellen, dass berechnete Emissionen nicht veränderbar sind.",
  common_general_lock_version_question: "Möchten Sie die Version wirklich sperren?",
  common_general_lock_version_warning:
    "Sobald eine Version gesperrt ist, sind die <b> Daten dieser Version</b> sowie der <b>Startmonat der Folgeversion unveränderlich</b>.",
  common_general_locked_version_description:
    "Das Sperren einer Version ist nur möglich, wenn es eine Folgeversion gibt und wenn die Daten der Version, die gesperrt werden soll, vollständig sind.",
  common_general_version_adding_description:
    "Beim Hinzufügen einer neuen Version werden die Daten aus der letzten Version kopiert. Die Daten einer Version können nach hinzufügen der neuen Version geändert werden.",
  common_general_information: "Informationen",
  common_general_title: "Titel",
  common_general_description: "Beschreibung",
  common_general_value: "Wert",
  common_general_deadline: "Abgabefrist",
  common_general_yes: "Ja",
  common_general_no: "Nein",
  common_general_or: "Oder",
  common_general_drag_drop: "Ziehen Sie Ihre .xlsx Datei hierher",
  common_general_choose_file: "Datei auswählen",
  common_general_file_selected: "Datei ausgewählt",
  common_general_row_success_import: "{0} Zeile(n) erfolgreich importiert",
  common_general_checks: "Prüfungen",
  common_number_of_employees: "Anzahl an Mitarbeitenden",
  common_general_managed: "Verwaltet",
  common_general_co2_type: "CO<sub>2</sub> Typ",
  common_general_financial_model: "Finanzmodell",
  common_general_geographies: "Geografien",
  common_general_documentation: "Dokumentation",
  common_general_documentation_description:
    "Für eine lückenlose, leicht zugängliche Dokumentation (auch im Falle einer Prüfung), können Sie sie diese hier in Form von Dokumenten und Freitext hinterlegen.",
  common_general_documentation_files: "Dokumentationsdateien",
  common_general_files: "Dateien",
  common_general_reset: "Zurücksetzen",
  common_general_disable: "Deaktivieren",
  common_general_co2_category_deactivated: "CO<sub>2</sub>-Kategorie ist deaktiviert",
  common_general_click_to_copy_id: "ID kopieren",
  common_general_id_copied: "ID kopiert!",
  common_general_co2_type_tag: "CO<sub>2</sub>-Typ-Tag",
  common_general_tag: "Tag",
  common_general_tags: "Tags",
  common_general_click_to_see: "Klicken, um Details anzuzeigen",
  common_general_you: "Sie",
  common_general_view_more: "Mehr anzeigen",
  common_general_error_message: "Fehlermeldung",
  common_general_row_number: "Zeilennummer",
  common_general_message: "Nachricht",
  common_general_file_processed: "Die Datei wurde verarbeitet",
  common_general_download_results: "Ergebnisse herunterladen",
  common_general_results_not_downloaded: "Ergebnisse nicht heruntergeladen",
  common_general_results_not_downloaded_message:
    "Die Ergebnisse wurden nicht heruntergeladen. Möchten Sie wirklich ohne Herunterladen der Upload-Ergebnisse fortfahren?",
  common_general_show_active: "Aktive anzeigen",
  common_general_problems: "Probleme",
  common_general_issues: "Probleme",
  common_general_overview: "Überblick",
  common_general_external_ids: "Externe IDs",
  common_general_sender_info: "Absenderinformationen",
  common_general_sender: "Absender",
  common_general_recipient: "Empfänger",
  common_general_started_at: "Begonnen am",
  common_general_created_after: "Erstellt nach",
  common_general_created_before: "Erstellt vor",
  common_general_items_per_page: "Elemente pro Seite",
  common_general_jump_to_page: "Springe zu",
  common_general_drag_drop_file: "Ziehen Sie Ihre Datei per Drag & Drop",
  common_general_click_here: "Klicken Sie hier",

  // Not found
  common_general_errors: "Fehler",
  common_not_found_text: "Nicht gefunden",
  common_not_found_button: "Weiter zur Plattform",
  common_not_in_use: "ungenutzt",
  common_upload_size_error: "Datei ist zu groß. Bitte wählen Sie eine Datei kleiner als 5 Megabyte aus.",
  common_no_file_uploaded: "Keine Datei hochgeladen",

  // Actions
  common_general_actions: "Aktionen",
  common_general_search: "Suche",
  common_general_free_text_search: "Freie Textsuche",
  common_general_edit: "Bearbeiten",
  common_general_exit: "Ausloggen",

  // Address
  common_industry: "Branche",
  common_street: "Straße",
  common_house_number: "Haus-Nr.",
  common_postal_code: "PLZ",
  common_city: "Stadt",
  common_country: "Land",
  common_origin: "Start",
  common_destination: "Ziel",
  common_transit: "Halt/Transit",
  common_geography: "Geographie",

  // Already logged in dialog
  common_already_logged_in: "Sie sind bereits eingeloggt.",
  common_to_launchpad: "Zur Plattform",

  // Delete swal dialog
  common_duplicate_dialog_title: "Duplizieren",
  common_duplicate_dialog_description: "Wollen Sie das Element wirklich duplizieren?",
  common_duplicate_dialog_confirm_button: "Ja, duplizieren!",

  // Delete swal dialog
  common_delete_dialog_title: "Löschung",
  common_delete_dialog_description: "Wollen Sie das Element wirklich löschen?",
  common_delete_dialog_confirm_button: "Löschen",

  // Units
  common_units: "Einheiten",
  common_units_unit: "Einheit",
  common_units_kg: "kg",

  // Input rules
  common_input_rule_required: "Pflichtfeld",
  common_input_rule_file_required: "Sie müssen die Datei hochladen",
  common_input_rule_number_must_be_number: "Die Eingabe muss eine Zahl sein",
  common_input_rule_number_must_be_positive: "Der Wert muss positiv sein",
  common_input_rule_number_must_not_be_negative: "Der Wert darf nicht negativ sein",
  common_input_rule_number_greater_than_equal_and_less_than_equal: "Der Wert zwischen {0} und {1} liegen",
  common_input_rule_number_greater_than_and_less_than_equal: "Der Wert muss größer als {0} und darf maximal {1} sein",
  common_input_rule_space_not_allowed: "Leerzeichen sind nicht erlaubt",
  common_input_rule_exceeds_maximum_length: "Überschreitet maximale Länge von {0} Zeichen",
  common_input_rule_bellow_minimum_length: "Muss mindestens {0} Zeichen lang sein",
  common_input_rule_length_between: "Muss zwischen {0} und {1} Zeichen lang sein",
  common_input_rule_at_least_one_letter: "Muss mindestens einen Buchstaben haben",
  common_input_rule_at_least_one_number: "Muss mindestens eine Nummer haben",
  common_input_rule_at_least_one_special_character:
    "Muss mindestens ein Sonderzeichen enthalten (!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~)",
  common_input_rule_email_must_match_pattern: "Muss eine E-Mail sein",
  common_input_rule_order_period_must_match_pattern: "Eingabe muss im Format YYYY-MM sein",
  common_input_rule_order_period_invalid_year: "Das Jahr muss größer als 2000 sein",
  common_input_rule_order_period_invalid_month: "Der Monat muss zwischen 01 und 12 sein",
  common_input_rule_passwords_do_not_match: "Passwörter stimmer nicht überein",
  common_input_rule_version_period_pattern: "Muss im Format YYYY-MM sein",
  common_input_rule_must_satisfy_symbol_pattern: "Muss dem Symbolmuster entsprechen",
  common_input_must_be_phone_number: "Muss eine Telefonnummer sein",

  // Common State
  common_enum_accepted: "Angenommen",
  common_enum_active: "Aktiv",
  common_enum_approved_by_customer: "Vom Kunden freigegeben",
  common_enum_approved_by_system_admin: "Freigegeben durch Systemadministrator",
  common_enum_closed: "Geschlossen",
  common_enum_company_admin: "Kunden Admin",
  common_enum_company_user: "Nutzer",
  common_enum_completed: "Abgeschlossen",
  common_enum_uncompleted: "Unvollständig",
  common_enum_relevant: "Relevant",
  common_enum_not_relevant: "Nicht Relevant",
  common_enum_connected: "Verbunden",
  common_enum_created: "Erstellt",
  common_enum_activated: "Aktiviert",
  common_enum_deactivated: "Deaktiviert",
  common_enum_expired: "Abgelaufen",
  common_enum_in_progress: "In Bearbeitung",
  common_enum_in_review: "In Review",
  common_enum_not_started: "Nicht begonnen",
  common_enum_not_in_use: "Nicht in Verwendung",
  common_enum_all_except_deactivated: "Alle außer Deaktiviert",
  common_enum_not_defined: "Nicht definiert",
  common_enum_on_hold: "Aufgehalten",
  common_enum_queued_for_distance_calculation: "In Warteschlange für Distanzberechnung",
  common_enum_error_in_distance_calculation: "Fehler bei Distanzberechnung",
  common_enum_open: "Offen",
  common_enum_ordered: "Bestellt",
  common_enum_paid: "Bezahlt",
  common_enum_pending: "Ausstehend",
  common_enum_released: "Freigegeben",
  common_enum_ccf_user: "GLOBAL FOOTPRINT-Benutzer",
  common_enum_compliance_user: "GLOBAL COMPLIANCE Benutzer",
  common_enum_system_admin: "System Admin",
  common_enum_uploaded: "Hochgeladen",
  common_enum_void: "Void",
  common_enum_waiting_for_customer: "Warten auf Kunden",
  common_enum_waiting_for_support: "Warten auf Unterstützung",
  common_enum_cancelled: "Storniert",
  common_enum_finalized: "Finalized",
  common_enum_success: "Erfolg",
  common_enum_failed: "Gescheitert",
  common_enum_ignored: "Unbeachtet",
  common_enum_archived: "Archiviert",
  common_enum_upstream: "Vorgelagert",
  common_enum_downstream: "Nachgelagert",
  common_enum_updating: "Aktualisierung",
  common_enum_locked: "Gesperrt",

  // File upload
  common_upload_in_progress: "Hochladen läuft... Bitte schließen Sie diese Seite nicht.",
  common_file_not_processed: "Datei konnte nicht verarbeitet werden",
  common_file_not_processed_description: "Die Datei konnte nicht verarbeitet werden. Folgende Fehler sind aufgetreten",
  common_rows_with_errors_description: "Die folgenden Zeilenfehler sind aufgetreten",
  common_file_download_sample: "Beispieldatei herunterladen",
  common_data_upload: "Hochgeladene Dateien",
  common_file_upload_only_single_file_supported: "Es kann nur eine einzelne Datei ausgewählt werden.",
  common_file_upload_wrong_file_type: "Falscher Dateityp. Es wird nur Excel (.xlsx) unterstützt.",
  common_file_upload_review_excel_data: "Bitte überprüfen Sie die aus der Excel-Datei ausgelesenen Daten",
  common_file_could_not_processed:
    "Die Datei konnte nicht verarbeitet werden. Es sind die folgenden Fehler aufgetreten",
  common_file_please_correct_the_file: "Bitte korrigieren Sie die Excel-Datei, bevor Sie sie erneut hochladen.",
  common_file_upload_wrong_documentation_file_type:
    "Falscher Dateityp. Es werden nur die Formate .jpeg, .jpg, .png, .pdf, .docx und .doc unterstützt.",

  // Common texts
  common_please_wait: "Bitte warten...",
  common_more: "mehr...",
  common_general_copied: "Kopiert",

  // Others
  common_component_type_not_supported: "Typ {0} wird nicht unterstützt.",
  common_unsaved_title: "Nicht gespeicherte Änderungen",
  common_unsaved_message: "Änderungen wurden nicht gespeichert, wollen Sie den Dialog wirklich schließen?",
  common_explanation_video: "Erklärungsvideo",

  // Survey welcome
  common_survey_welcome_title: "Sie sind zur Umfrage eingeladen!",
  common_survey_welcome_already_have_an_account: "Sie haben bereits ein Konto?",
  common_survey_welcome_survey_deadline: "Die Abgabefrist der Umfrage ist:",
  common_survey_welcome_invitation_code_used:
    "<b>Der Einladungscode zur Umfrage wurde bereits verwendet</b>, Sie können sich mit diesem Link nicht mehr registrieren.",
  common_survey_welcome_go_to_login: "Zum Login",
  common_survey_welcome_content_info_1_esg:
    "<b>{partnerName}</b> führt eine <b>ESG Risiko Analyse</b> durch. Im Rahmen dieses Prozesses bittet <b>{partnerName}</b> Sie als Lieferanten, eine Umfrage zu beantworten.",
  common_survey_welcome_content_info_1_lksg:
    "<b>{partnerName}</b> führt zur Erfüllung der gesetzlichen Anforderungen eine LkSG Risiko Analyse durch. Im Zuge dessen bittet <b>{partnerName}</b> Ihr Unternehmen als Zulieferer, eine Umfrage zu beantworten.",
  common_survey_welcome_content_info_1_fallback:
    "<b>{partnerName}</b> bittet alle Lieferanten, eine Umfrage zu beantworten.",
  common_survey_welcome_content_info_2:
    "Um an der Umfrage teilnehmen zu können, müssen Sie sich auf der GLOBAL SUITE registrieren oder anmelden.",
  common_survey_welcome_invitation_code_not_found: "Einladungscode nicht gefunden!",
  common_survey_welcome_check_invitation_code: "Überprüfen Sie den Einladungscode und versuchen Sie es erneut.",
  common_survey_welcome_registration_completed: "Registrierung erfolgreich abgeschlossen",
  common_general_licenses: "Lizenzen",
  common_auditing_audits: "Revisionen",
  common_news_new_update: "Neues Update",
};
